/*
 * Polyfill stable language features.
 * It's recommended to use @babel/preset-env and browserslist
 * to only include the polyfills necessary for the target browsers.
 */

 // ? Uncomment if your clients are using IE - @Pushkin
// import 'core-js/stable';

// import 'regenerator-runtime/runtime';

/**
 * This file contains polyfills loaded on all browsers
 **/
